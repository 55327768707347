// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-students-js": () => import("../src/pages/addStudents.js" /* webpackChunkName: "component---src-pages-add-students-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-messages-js": () => import("../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-resources-js": () => import("../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-schools-js": () => import("../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-send-message-js": () => import("../src/pages/sendMessage.js" /* webpackChunkName: "component---src-pages-send-message-js" */),
  "component---src-pages-settings-js": () => import("../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-students-list-js": () => import("../src/pages/studentsList.js" /* webpackChunkName: "component---src-pages-students-list-js" */)
}

