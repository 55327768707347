const initialState = {
  user: "",
  isLoggedin: false,
  educatorsList: "",
  educator: "",
  eventsList: "",
  fetching_start: false,
  fetching_end: false,
  fetching: "",
  resourcesList: [],
  resourcesUrl: [],
  studentsList: [],
  messages: [],
  allStudentsList: [],
  admin: false,
  candidate_no_prefix: 1000000,
  total_students: 0
}

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EVENT_LIST":
      return {
        ...state,
        eventsList: action.payload,
      }
    case "IS_ADMIN":
      const isAdmin = state.educatorsList.filter(
        educator => educator.admin === true && educator.email === state.user.user.email,
      ).length > 0
      return {
        ...state,
        admin: isAdmin,
      }
    case "TOTAL_STUDENTS_NO":
      return {
        ...state,
        total_students: action.payload,
      }
    case "FETCH_STUDENTS_LIST":
      return {
        ...state,
        studentsList: action.payload,
      }
    case "FETCH_RESOURCES_LIST":
      return {
        ...state,
        resourcesList: action.payload,
      }
    case "FETCH_RESOURCES_URL":
      return {
        ...state,
        resourcesUrl: action.payload,
      }
    case "FETCH_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      }
    case "ADD_STUDENT":
      return {
        ...state,
        studentsList: action.payload,
      }
    case "FETCH_EDUCATORS_LIST":
      return {
        ...state,
        educatorsList: action.payload,
      }
    case "FETCH_EDUCATOR":
      return {
        ...state,
        educator: action.payload,
      }
    case "FETCH_ALL_STUDENTS":
      return {
        ...state,
        allStudentsList: action.payload,
      }
    case "ADD_EVENT":
      return {
        ...state,
        eventsList: action.payload,
      }

    case "FETCHING":
      const status = (state.fetching === "ended")
      return {
        ...state,
        fetching: action.payload,
      }

    case "EVENT_UPDATED":
      return {
        ...state,
      }
    case "GET_DATA":
      return {
        ...state,
        SavedArticles: action.payload,
      }
    case "SET_DATA":
      return {
        ...state,
        SavedArticles: action.payload,
      }
    case "SAVE_DATA":
      return {
        ...state,
        data: action.payload,
      }
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        isLoggedin: true,
        Error: "",
      }

    case "SIGN_UP":
      return {
        ...state,
        user: action.payload,
        isLoggedin: true,
        Error: action.payload.error,
      }
    case "SET_ONLINE_STATUS":
      return {
        ...state,
        user: action.payload,
        isLoggedin: true,
      }
    case "LOGOUT":
      return {
        ...state,
        user: "",
        isLoggedin: action.payload,
        SavedArticles: "",
        Error: "",
      }
    case "ERROR":
      return {
        ...state,
        user: "",
        Error: action.payload,
      }

    default:
      return state
  }

}

export default mainReducer
